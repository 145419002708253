import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { formatPredictionScore } from '../../../utils';
import { HighlightedSearchTerm } from '../../HighlightedSearchTerm/HighlightedSearchTerm';
import { CircularScoring, CircularProgressBarWrapper, NestedProgressBar } from './style';

interface ICircularProgressBar {
  score: number;
  search: string;
  size?: string;
  fontSize?: string;
  withSearchHighlight?: boolean;
  isViewed?: boolean;
}
export const CircularProgressBar: React.FC<ICircularProgressBar> = ({
  score,
  withSearchHighlight,
  size,
  search,
  isViewed,
}) => {
  const scoreAsString = formatPredictionScore(score);

  const scoreToShow = withSearchHighlight ? (
    <HighlightedSearchTerm text={scoreAsString} searchTerm={search} />
  ) : (
    scoreAsString
  );

  return (
    <CircularProgressBarWrapper size={size}>
      <CircularProgressbar
        value={100}
        strokeWidth={9}
        styles={buildStyles({
          pathColor: 'var(--light-white)',
          trailColor: 'transparent',
          textColor: 'var(--almost-black)',
        })}
      />
      <CircularScoring
        data-test-id="circularScoring"
        fontSize={isViewed ? '15px' : '15px'}
        fontWeight={isViewed ? '200' : '600'}
        size={size}
      >
        {scoreToShow}
      </CircularScoring>
      <NestedProgressBar>
        <CircularProgressbar
          value={score}
          strokeWidth={9}
          styles={buildStyles({
            pathColor: 'var(--almost-black)',
            trailColor: 'transparent',
            strokeLinecap: 'round',
          })}
        />
      </NestedProgressBar>
    </CircularProgressBarWrapper>
  );
};
