import { UAParser } from 'ua-parser-js';
import { IAuthSlice } from '@skillset/onelogin';

import { brandColors } from '../constants';
import { IEmployersSimulationSlice, StarredFrom } from '../store/types/EmployersSimulationSliceInterface';
import {
  ICoreTaskScore,
  SHOW_NEWLABEL_TIMESTAMP_DIFF,
  PREDICTIONSCORE_MAX,
  IEmployersSimulation,
} from '../constants/simulation';
import { IStatuses } from './types';
import { AdministrationFilterBy } from '../pages/Administration/types';
import { EmployersAdministrationSlice } from '../store/types/EmployerAdministrationInterface';

export const getNameInitials = (name: string) => {
  const splittedName = name.toUpperCase().split(' ');
  if (splittedName.length < 2) return 'N/A';
  return `${splittedName[0].substring(0, 1)}${splittedName[1].substring(0, 1)} `;
};

export const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0');
};

/**
 * The format is: MM/DD/YY
 */
export const formatDate = (date: Date) => {
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2); // Take last two digits of the year
  return `${month}/${day}/${year}`;
};

/**
 * The format is: HH:MM
 */
export const formatTime = (date: Date) => {
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

/**
 * inspired by https://github.com/kolodny/weak-key
 */
const keysMap = new WeakMap<object, string>();
let index = 0;

/**
 * generate react key for objects
 */
export const weakKey = <T extends object>(obj: T) => {
  let key = keysMap.get(obj);
  if (!key) {
    key = 'weak-key-' + index++;
    keysMap.set(obj, key);
  }
  return key;
};

/**
 * returns true for mobile
 */
export const detectMobile = () => {
  const parser = new UAParser();

  return UAParser.DEVICE.MOBILE === parser.getDevice().type;
};
/**
 * returns true for Apple Mobile devices
 */
export const detectAppleMobileDevice = () => {
  const parser = new UAParser();
  return parser.getDevice().vendor === 'Apple' && UAParser.DEVICE.MOBILE === parser.getDevice().type;
};
/**
 * returns true for Firefox
 */
export const detectFirefox = () => {
  const parser = new UAParser();
  return parser.getBrowser().name === 'Firefox';
};

export const extractNumber = (str: string): number => {
  const match = str.match(/\((\d+)\)/);
  if (match && match[1]) {
    return parseInt(match[1], 10);
  }
  return 0;
};

export const getDateStringOrNA = (date: ReturnType<Date['toJSON']> | null) => {
  if (date) {
    const dateObject = new Date(date);
    return `${formatDate(dateObject)} ${formatTime(dateObject)}`;
  } else return 'N/A';
};

export const getBrandColorByOrder = (idx: number) => {
  const colorIndex = idx % brandColors.length;
  return brandColors[colorIndex];
};
export const formatStatusForCSV = <T extends IEmployersSimulation>(simulation: T, statuses?: IStatuses[]) => {
  return !simulation.candidateStatus || simulation.candidateStatus === 0
    ? 'No Status'
    : statuses?.find((status) => status.id === simulation.candidateStatus)?.name || 'No Status';
};

export const getCoreTasksCSVEntries = <T extends IEmployersSimulation>(simulation: T) => {
  const coreTasks = Object.keys(simulation).filter((item) => item.startsWith('coreTask'));
  const coreTaskScores: Record<string, number> = {};
  coreTasks.forEach((coreTask) => {
    const key = coreTask as keyof IEmployersSimulation;
    const { title, score } = simulation[key] as ICoreTaskScore;
    coreTaskScores[title] = score;
  });
  return Object.entries(coreTaskScores);
};

export const isUserBelongsToBusiness = (platformUser: IAuthSlice['platformUser']) => {
  //     Using != null for covering null or undefined string case.
  return platformUser?.businessId != null && platformUser?.businessId != undefined;
};

export const isNewSimulation = (updatedAt: ReturnType<Date['toJSON']>) =>
  new Date(updatedAt).getTime() > Date.now() - SHOW_NEWLABEL_TIMESTAMP_DIFF;

export const normalizePredictionScore = (score: number) => Math.round((score / PREDICTIONSCORE_MAX) * 100);

export const formatPredictionScore = (score: number) => `${Math.round(score)}%`;

export const formatCoreTaskScore = (score: number) => {
  const twoDigitsGrade = score.toPrecision(2);
  const gradeAsString =
    twoDigitsGrade.length > 3 ? twoDigitsGrade.substring(0, 3) : twoDigitsGrade === '0.0' ? '0' : twoDigitsGrade;
  return gradeAsString;
};

export const filterSimulations = <T extends StarredFrom>(
  args: Pick<IEmployersSimulationSlice, T | 'search'>,
  userBelongsToBusiness: boolean,
  origin: T,
) => {
  const { [origin]: simulations } = args;
  let { search } = args;
  search = search.trim();
  const filteredSimulations = simulations.filter((sim) => {
    const { name, phone, email, updatedAt, predictionScore } = sim;
    const coreTasks = Reflect.ownKeys(sim).filter((key) => /^coreTask\d+$/.test(String(key))) as `coreTask${number}`[];

    const date = new Date(updatedAt);

    const valuesToCheck = [
      name,
      phone,
      email,
      formatDate(date),
      formatPredictionScore(normalizePredictionScore(predictionScore)),
      ...coreTasks.map((key) => formatCoreTaskScore(sim[key].score)),
    ];
    if (!userBelongsToBusiness) valuesToCheck.push(getNameInitials(name));

    const okByString = valuesToCheck.some((str) => {
      return str?.toLowerCase().includes(search.toLowerCase());
    });

    return okByString;
  });
  return filteredSimulations;
};

export const filterAdministrationUsers = (
  administrationSlice: EmployersAdministrationSlice,
  filterBy: AdministrationFilterBy,
) => {
  const { users, searchTerm } = administrationSlice;
  let filteredUsers = users.filter((user) => {
    let shouldUserBeIncluded = true;
    if (filterBy.statuses.length > 0) {
      shouldUserBeIncluded = filterBy.statuses.includes(user.status);
    }
    if (filterBy.roles.length > 0) {
      shouldUserBeIncluded = filterBy.roles.includes(user.role);
    }
    return shouldUserBeIncluded;
  });
  filteredUsers = filteredUsers.filter((user) => {
    const { email, fullName, lastVisit, role, status, expirationDate } = user;
    const lastActivity = getDateStringOrNA(lastVisit);
    const columnsToMatchSearchTerm = [
      email,
      fullName,
      lastActivity,
      role,
      expirationDate && status === 'Invitation was expired'
        ? `${status} on ${formatDate(new Date(expirationDate))}`
        : status,
    ];
    const okByString = columnsToMatchSearchTerm.some((str) => {
      return str?.toLowerCase().includes(searchTerm.toLowerCase());
    });

    return okByString;
  });

  return filteredUsers;
};

export const getFirstNameFirstLetter = (firstName: string | undefined) =>
  Array.from(firstName ?? '')[0]?.toUpperCase() || 'N/A'; //this one works for weird unicodes

export type { IEmployersSimulation };

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

type OptionalSortValues = string | number | Date;
export const compareSortValues = <T extends OptionalSortValues>(A: T, B: T, orderBy: 'ASC' | 'DESC'): 1 | -1 => {
  const expression = orderBy === 'ASC' ? A > B : A < B;
  return expression ? 1 : -1;
};

export const toCamelCase = (str: string): string => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, idx) => {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return idx === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const isUrl = (str: string) => {
  const regex = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // validate fragment locator
  return regex.test(str);
};

export function generateRandomHash() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const regularLetters = 'abcdefghijklmnopqrstuvwxyz';
  const capitalLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';

  let hash = '';

  // Add one regular letter
  hash += regularLetters.charAt(Math.floor(Math.random() * regularLetters.length));

  // Add one capital letter
  hash += capitalLetters.charAt(Math.floor(Math.random() * capitalLetters.length));

  // Add one number
  hash += numbers.charAt(Math.floor(Math.random() * numbers.length));

  // Fill the remaining characters
  for (let i = 0; i < 5; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    hash += characters.charAt(randomIndex);
  }

  return hash;
}
