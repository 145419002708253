import { useState } from 'react';
import { XIconButton } from '../../reusable-elements/XIconButton';
import {
  InviteForm,
  InviteUsersPopupElement,
  PopupHeader,
  RoleContainer,
  RoleDescription,
  RoleIconContainer,
  RoleTitle,
  RoleTitleAndDescContainer,
  StyledTextArea,
  TitleElement,
  ViewerRoleIcon,
} from './style';
import { EmailAddressesInput } from './EmailAddressesInput/EmailAddressesInput';
import { EMAIL_REGEX } from '../../utils';
import { useAppDispatch } from '../../store/store';
import { inviteBusinessUsers } from '../../store/actions/employerAdministration.action';
import { TransparentBackDropLayer, PrimaryButton, ToastNotificationType } from '@skillset/ui';
import { Tooltip } from '../../reusable-elements/ToolTip';
import { BusinessUserRole } from '../../pages/Administration/types';
import { zIndices } from '../../assets/zIndices';

interface InviteUsersPopupProps {
  setToastMessage: (message: string, type: ToastNotificationType) => void;
  closePopup: () => void;
}

const saveButtonStyle = {
  padding: '10px 14px',
  fontSize: '14px',
  background: 'var(--almost-black)',
  alignSelf: 'flex-end',
};

export const InviteUsersPopup: React.FC<InviteUsersPopupProps> = ({ setToastMessage, closePopup }) => {
  const [emailInput, setEmailInput] = useState('');
  const [errorTooltipDisplay, setErrorTooltipDisplay] = useState(false);
  const [form, setForm] = useState<{ emails: string[]; invitationText: string; role: BusinessUserRole }>({
    emails: [],
    invitationText: '',
    role: 'viewer',
  });

  const dispatch = useAppDispatch();

  const displayErrorMessage = () => {
    setErrorTooltipDisplay(true);
    setTimeout(() => {
      setErrorTooltipDisplay(false);
    }, 3000);
  };

  const addValidEmailToList = () => {
    if (EMAIL_REGEX.test(emailInput)) {
      if (!form.emails.includes(emailInput)) {
        setForm((prevFormState) => {
          return { ...prevFormState, emails: [...prevFormState.emails, emailInput] };
        });
      }
      setEmailInput('');
    } else if (emailInput.length > 0) {
      displayErrorMessage();
    }
  };

  const submitInviteForm: React.FormEventHandler<HTMLFormElement> = (ev) => {
    ev.preventDefault();
    if (form.emails.length > 0) {
      dispatch(inviteBusinessUsers({ emails: form.emails, invitationText: form.invitationText, role: form.role }));
      const toastMessage =
        form.emails.length === 1
          ? `${form.emails.length} User was invited to your team!\nWe’ll send an invitation email soon`
          : `${form.emails.length} Users were invited to your team!\nWe’ll send them an invitation email soon`;
      setToastMessage(toastMessage, 'success');
    }
    closePopup();
  };

  const onEmailInputChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    ev.stopPropagation();
    const value = ev.target.value;
    setEmailInput(value);
  };

  const onInvitationTextChange: React.ChangeEventHandler<HTMLTextAreaElement> = (ev) => {
    const value = ev.target.value;
    setForm((prevFormState) => {
      return { ...prevFormState, invitationText: value };
    });
  };

  const onRemoveEmailAddress = (emailAddress: string) => {
    setForm((prevFormState) => {
      return { ...prevFormState, emails: prevFormState.emails.filter((eAddress) => eAddress !== emailAddress) };
    });
  };

  const onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (ev) => {
    if (ev.key === 'Enter' || ev.key === ',' || (ev.key === 'Tab' && emailInput)) {
      ev.preventDefault();
      addValidEmailToList();
    }
  };

  return (
    <>
      <InviteUsersPopupElement>
        <PopupHeader>
          <TitleElement>Invite Users</TitleElement>
          <XIconButton onClick={closePopup} />
        </PopupHeader>
        <InviteForm onSubmit={submitInviteForm}>
          <EmailAddressesInput
            value={emailInput}
            emailsList={form.emails}
            onInputKeyDown={onInputKeyDown}
            onChange={onEmailInputChange}
            onRemoveEmailAddress={onRemoveEmailAddress}
            handleBlur={addValidEmailToList}
          />
          <StyledTextArea
            placeholder="Write your personal message"
            value={form.invitationText}
            onChange={onInvitationTextChange}
          />
          <RoleContainer>
            <RoleIconContainer>
              <ViewerRoleIcon />
            </RoleIconContainer>
            <RoleTitleAndDescContainer>
              <RoleTitle>Viewer</RoleTitle>
              <RoleDescription>
                Can see new candidates, download personal details and skills preferments.
              </RoleDescription>
            </RoleTitleAndDescContainer>
          </RoleContainer>
          <PrimaryButton type="submit" text="Send" style={saveButtonStyle} disabled={form.emails.length === 0} />
        </InviteForm>
        {errorTooltipDisplay && (
          <Tooltip direction="down" style={{ top: '50px' }}>
            Email address is not valid
          </Tooltip>
        )}
      </InviteUsersPopupElement>
      <TransparentBackDropLayer zIndex={zIndices.backdrop} onClick={closePopup} />
    </>
  );
};
