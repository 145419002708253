import { API } from '@skillset/onelogin';
import { SuccessResponse } from '../utils/types';
import { ISortBy } from '../hooks/useSortBy';
import { compareSortValues } from '../utils';
import { Customer } from '../store/types/EmployerCustomersInterface';

const getAllCustomers = () => {
  return API.get('api', '/employers/customers', {}) as Promise<SuccessResponse<Customer[]>>;
};
const getCustomerInfo = (customerUUID: string) => {
  const queryStringParameters = { customerUUID } as const;
  return API.get('api', '/employers/customers', { queryStringParameters }) as Promise<SuccessResponse<Customer>>;
};

export const sortCustomers = (customers: Customer[], sortBy: ISortBy) => {
  const { keyName, orderBy } = sortBy;
  switch (keyName) {
    case 'customerName':
    case 'location':
    case 'agreementDate':
    case 'interactions':
      return customers.sort((a, b) => {
        return compareSortValues(a[keyName], b[keyName], orderBy);
      });
    default:
      return customers;
  }
};

export const CustomerService = {
  getAllCustomers,
  getCustomerInfo,
  sortCustomers,
};
