import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AdministrationService } from '../../services/administrationService';
import axios from '@aws-amplify/api-rest/node_modules/axios/index';
import moment from 'moment';

import {
  BusinessUser,
  BusinessUserRole,
  InvitedBusinessUser,
  OptionalBusinessUser,
  SuspendedUser,
} from '../../pages/Administration/types';
import { WithTypesType } from '../types/WithTypesType';

export const updateUsersList = createAction(
  'employerAdministration/updateUsersList',
  (payload: { users: OptionalBusinessUser[] }) => {
    return { payload };
  },
);

export const getBusinessUsers = createAsyncThunk('employerAdministration/getBusinessUsers', async (_, thunkAPI) => {
  try {
    const { payload } = await AdministrationService.getAll();
    return payload;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data.name);
  }
});

export const inviteBusinessUsers = createAsyncThunk.withTypes<WithTypesType>()(
  'employerAdministration/inviteBusinessUsers',
  async (
    params: {
      emails: string[];
      invitationText: string;
      role: BusinessUserRole;
    },
    thunkAPI,
  ) => {
    const { users } = thunkAPI.getState().employersAdministrationSliceReducer;
    const currentUsers = users;
    const invitedUsers: InvitedBusinessUser[] = params.emails.map((email) => {
      /**
       * Calculate the expiration date, set to 14 days from the current date and time.
       */
      const expirationDate = new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000).toString();
      return {
        fullName: null,
        email,
        role: params.role,
        status: 'Invitation was sent',
        lastVisit: null,
        expirationDate,
      };
    });
    const usersToUpdate = [...invitedUsers, ...currentUsers];
    thunkAPI.dispatch(updateUsersList({ users: usersToUpdate }));
    try {
      const { payload } = await AdministrationService.inviteUsers(params);
      return payload;
    } catch (err) {
      thunkAPI.dispatch(updateUsersList({ users: currentUsers }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);

export const removeUser = createAsyncThunk.withTypes<WithTypesType>()(
  'employerAdministration/removeUser',
  async (email: string, thunkAPI) => {
    const { users } = thunkAPI.getState().employersAdministrationSliceReducer;
    const currentUsers = users;
    const usersToUpdate = users.filter((user) => user.email !== email);
    thunkAPI.dispatch(updateUsersList({ users: usersToUpdate }));
    try {
      const { payload } = await AdministrationService.removeUser(email);
      return payload;
    } catch (err) {
      thunkAPI.dispatch(updateUsersList({ users: currentUsers }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);
export const suspendUser = createAsyncThunk.withTypes<WithTypesType>()(
  'employerAdministration/suspendUser',
  async (email: string, thunkAPI) => {
    const { users } = thunkAPI.getState().employersAdministrationSliceReducer;
    const currentUsers = users;
    const usersToUpdate = users.map((user) =>
      user.email === email ? ({ ...user, status: 'User is suspended' } as SuspendedUser) : user,
    );
    thunkAPI.dispatch(updateUsersList({ users: usersToUpdate }));
    try {
      const { payload } = await AdministrationService.suspendUser(email);
      return payload;
    } catch (err) {
      thunkAPI.dispatch(updateUsersList({ users: currentUsers }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);
export const removeInvitedUser = createAsyncThunk.withTypes<WithTypesType>()(
  'employerAdministration/removeInvitedUser',
  async (email: string, thunkAPI) => {
    const { users } = thunkAPI.getState().employersAdministrationSliceReducer;
    const currentUsers = users;
    const usersToUpdate = users.filter((user) => user.email !== email);
    thunkAPI.dispatch(updateUsersList({ users: usersToUpdate }));
    try {
      const { payload } = await AdministrationService.removeInvitedUser(email);
      return payload;
    } catch (err) {
      thunkAPI.dispatch(updateUsersList({ users: currentUsers }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);
export const unsuspendUser = createAsyncThunk.withTypes<WithTypesType>()(
  'employerAdministration/unsuspendUser',
  async (email: string, thunkAPI) => {
    const { users } = thunkAPI.getState().employersAdministrationSliceReducer;
    const currentUsers = users;
    const usersToUpdate = users.map((user) =>
      user.email === email ? ({ ...user, status: 'User is active' } as BusinessUser) : user,
    );
    thunkAPI.dispatch(updateUsersList({ users: usersToUpdate }));
    try {
      const { payload } = await AdministrationService.unsuspendUser(email);
      return payload;
    } catch (err) {
      thunkAPI.dispatch(updateUsersList({ users: currentUsers }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);
export const resendInvitation = createAsyncThunk.withTypes<WithTypesType>()(
  'employerAdministration/resendInvitation',
  async (email: string, thunkAPI) => {
    const { users } = thunkAPI.getState().employersAdministrationSliceReducer;
    const currentUsers = users;

    const now = moment();
    const invitedUser = users.find((user) => user.email === email);

    if (invitedUser && now.isAfter(invitedUser.expirationDate)) {
      const newExpirationDate = now.add(2, 'weeks').toJSON();
      const usersToUpdate = users.map((user) =>
        user.email === email ? ({ ...user, expirationDate: newExpirationDate } as InvitedBusinessUser) : user,
      );
      thunkAPI.dispatch(updateUsersList({ users: usersToUpdate }));
    }
    try {
      const { payload } = await AdministrationService.resendInvitation(email);
      return payload;
    } catch (err) {
      thunkAPI.dispatch(updateUsersList({ users: currentUsers }));
      if (axios.isAxiosError(err)) {
        return thunkAPI.rejectWithValue(err.toJSON());
      }
      return thunkAPI.rejectWithValue(err.response?.data.name);
    }
  },
);

export const getUserIntegration = createAsyncThunk(
  'employersAdministration/getUserIntegration',
  async (_, thunkAPI) => {
    try {
      const { payload } = await AdministrationService.getUserIntegration();
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);
export const checkAPIKeyGenerated = createAsyncThunk(
  'employersAdministration/checkAPIKeyGenerated',
  async (_, thunkAPI) => {
    try {
      const checkAPIKeyGeneratedResponse = await AdministrationService.checkAPIKeyGenerated();
      return checkAPIKeyGeneratedResponse;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

export const generateApiKey = createAsyncThunk.withTypes<WithTypesType>()(
  'employersSimulation/generateApiKey',
  async (_, thunkAPI) => {
    try {
      const payload = await AdministrationService.generateApiKey();
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

export const addSimulationTypesToBussiness = createAsyncThunk.withTypes<WithTypesType>()(
  'employersSimulation/addSimulationTypesToBussiness',
  async (
    params: {
      simulationTypeIds: number[];
    },
    thunkAPI,
  ) => {
    const { simulationTypeIds } = params;
    try {
      const { payload } = await AdministrationService.addSimulationToBussiness(simulationTypeIds);
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

export const getUniqueLinkPerBussiness = createAsyncThunk.withTypes<WithTypesType>()(
  'employersSimulation/getUniqueLinkPerBussiness',
  async (_, thunkAPI) => {
    try {
      const { payload } = await AdministrationService.getUniqueLinkPerBussiness();
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);
export const getSimTypesPrimaryLinks = createAsyncThunk.withTypes<WithTypesType>()(
  'employersSimulation/getSimTypesPrimaryLinks',
  async (_, thunkAPI) => {
    try {
      const { payload } = await AdministrationService.getSimTypesPrimaryLinks();
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

export const getCustomers = createAsyncThunk.withTypes<WithTypesType>()(
  'employersSimulation/getCustomers',
  async (_, thunkAPI) => {
    try {
      const { payload } = await AdministrationService.getCustomers();
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

export const setCurrentCustomer = createAsyncThunk(
  'employersSimulation/setCurrentCustomer',
  async (
    params: {
      customerUUID: string;
      customerName: string;
    },
    thunkAPI,
  ) => {
    try {
      const { customerUUID, customerName } = params;
      return { customerUUID, customerName };
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

export const getSimUniqueAndApplicationLinks = createAsyncThunk.withTypes<WithTypesType>()(
  'employersSimulation/getSimUniqueAndApplicationLinks',
  async (
    params: {
      simulationTypeId: number;
    },
    thunkAPI,
  ) => {
    const { simulationTypeId } = params;
    try {
      const { payload } = await AdministrationService.getSimUniqueAndApplicationLinks(simulationTypeId);
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);
