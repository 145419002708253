import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ISortBy } from '../../hooks/useSortBy';
import { EmployerCustomersSlice } from '../types/EmployerCustomersInterface';
import { sortCustomers } from '../../services/customersService';
import { getAllCustomers, setCurrentCustomer } from '../actions/employerCustomers.action';

const initialState: EmployerCustomersSlice = {
  customers: [],
  loadingCustomers: false,
  currentCustomer: {
    customerName: '',
    customerUUID: '',
    agreementDate: '',
    interactions: 0,
    location: '',
    positions: [],
    totalInteractions: 0,
  },
  loadingCurrentCustomer: false,
};

const employerCustomersSlice = createSlice({
  name: 'employerCustomers',
  initialState: initialState,
  reducers: {
    applySortBy(state, { payload: { sortBy } }: PayloadAction<{ sortBy: ISortBy }>) {
      state.customers = sortCustomers(state.customers, sortBy);
    },
    resetCandidates(state) {
      state.customers = initialState.customers;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCustomers.pending, (state) => {
        state.loadingCustomers = true;
      })
      .addCase(getAllCustomers.rejected, (state) => {
        state.loadingCustomers = false;
      })
      .addCase(getAllCustomers.fulfilled, (state, { payload }) => {
        state.loadingCustomers = false;
        state.customers = payload;
      })
      .addCase(setCurrentCustomer.pending, (state) => {
        state.loadingCurrentCustomer = true;
      })
      .addCase(setCurrentCustomer.rejected, (state) => {
        state.loadingCurrentCustomer = false;
      })
      .addCase(setCurrentCustomer.fulfilled, (state, { payload }) => {
        state.loadingCurrentCustomer = false;
        state.currentCustomer = payload;
      });
  },
});

//Actions are also reducers functions
export const { applySortBy, resetCandidates } = employerCustomersSlice.actions;

export const employerCustomersSliceReducer = employerCustomersSlice.reducer;
