import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

export interface IGAParams {
  eventCategory: string;
  eventName: string;
  label: string;
  userID?: string;
}
const disableGTM = process.env.NODE_ENV === 'development';

export const platformAnalyticsInit = (googleAnalyticsCode: string, tagManagerCode: string) => {
  if (disableGTM) return;
  ReactGA.initialize(googleAnalyticsCode);
  TagManager.initialize({ gtmId: tagManagerCode });
};

export const triggerAnalyticsEvent = (params: IGAParams) => {
  if (disableGTM) return;
  ReactGA.event({
    category: params.eventCategory,
    action: params.eventName,
    label: params.label,
  });
  TagManager.dataLayer({
    dataLayer: {
      event: 'ga_event',
      category: params.eventCategory,
      action: params.eventName,
      label: params.label,
      userId: params.userID || '',
    },
  });
};
