import { useEffect, useState } from 'react';
import { Table, TableSearch, PageHeaderAndTitles } from '@skillset/ui';
import {
  PageHeaderControlsContainer,
  PageWithTableContainer,
} from '../../reusable-elements/Page/PageWithTableContainer';
import { TableAndFiltersWrapper } from '../../reusable-elements/TableAndFiltersWrapper';
import { useBusinessUsersTableColumns } from './columns/columns';
import { RootState, useAppDispatch } from '../../store/store';
import {
  ADMINISTRATION_MOBILE_GRID_TEMPLATE_COLUMNS,
  ADMINISTRATION_ROW_GRID_TEMPLATE_COLUMNS,
  INITIAL_SORTBY_STATE,
} from './constants';
import { getBusinessUsers } from '../../store/actions/employerAdministration.action';
import { useSelector } from 'react-redux';
import { applySortBy, setSearchTerm } from '../../store/reducers/employersAdministrationSlice';
import { InviteUsersButton } from './style';
import { InviteUsersPopup } from '../../components/InviteUsersPopup/InviteUsersPopup';
import { filterAdministrationUsers } from '../../utils';
import { AdministrationFilterSection } from './FilterSection/AdministrationFilterSection';
import { AdministrationFilterBy, INITIAL_ADMIN_FILTERBY } from './types';
import { EmployersAdministrationSlice } from '../../store/types/EmployerAdministrationInterface';
import {
  INITIAL_TOAST_CONFIG,
  ToastNotificationType,
  ToastNotificationWithDisplay,
  ToastNotification,
} from '@skillset/ui';
import { useSortBy } from '../../hooks/useSortBy';
import { zIndices } from '../../assets/zIndices';
export const Administration = () => {
  const dispatch = useAppDispatch();

  const [invitePopupDisplay, setInvitePopupDisplay] = useState(false);
  const [filterBy, setFilterBy] = useState<AdministrationFilterBy>(INITIAL_ADMIN_FILTERBY);
  const [sortBy, handleSortClick, getSortByOrder] = useSortBy(INITIAL_SORTBY_STATE);
  const [toastNotificationConfig, setToastNotificationConfig] =
    useState<ToastNotificationWithDisplay>(INITIAL_TOAST_CONFIG);
  const adminstrationSlice = useSelector<RootState, EmployersAdministrationSlice>(
    (state) => state.employersAdministrationSliceReducer,
  );

  const { users, searchTerm } = adminstrationSlice;

  const resetToastMessage = () => setToastNotificationConfig(INITIAL_TOAST_CONFIG);

  const setToastMessage = (message: string, type: ToastNotificationType) => {
    setToastNotificationConfig({
      display: true,
      message,
      type,
      zIndex: zIndices.mobilePopup,
    });

    setTimeout(() => {
      resetToastMessage();
    }, 3000);
  };
  const [businessUsersTableColumns] = useBusinessUsersTableColumns(setToastMessage);
  const updateCheckboxField = (category: string, value: string, checked: boolean) => {
    const castedCategory = category as keyof typeof filterBy;
    setFilterBy((prevFilterBy) => {
      return {
        ...prevFilterBy,
        [castedCategory]: checked
          ? [...prevFilterBy[castedCategory], value]
          : prevFilterBy[castedCategory].filter((option) => option !== value),
      };
    });
  };

  const isCheckboxOptionChecked = (category: string, value: string) => {
    return filterBy[category as keyof typeof filterBy].includes(value);
  };

  const filteredusers = filterAdministrationUsers(adminstrationSlice, filterBy);

  useEffect(() => {
    dispatch(getBusinessUsers());
  }, []);

  useEffect(() => {
    dispatch(applySortBy({ sortBy }));
  }, [sortBy]);

  const filterUsers = (search: string) => dispatch(setSearchTerm(search));

  return (
    <PageWithTableContainer>
      <PageHeaderAndTitles title="Users" subTitle="Admin - Company name">
        <PageHeaderControlsContainer>
          <TableSearch searchTerm={searchTerm} setSearchTerm={filterUsers} />
          <InviteUsersButton onClick={() => setInvitePopupDisplay(true)} />
        </PageHeaderControlsContainer>
      </PageHeaderAndTitles>
      <TableAndFiltersWrapper style={{ position: 'relative' }}>
        <AdministrationFilterSection
          updateCheckboxField={updateCheckboxField}
          isCheckboxOptionChecked={isCheckboxOptionChecked}
        />
        <Table
          zIndexTooltip={zIndices.sideMenuTooltip}
          rowGridTemplateColumns={ADMINISTRATION_ROW_GRID_TEMPLATE_COLUMNS}
          mobileRowGridTemplateColumns={ADMINISTRATION_MOBILE_GRID_TEMPLATE_COLUMNS}
          columns={businessUsersTableColumns}
          renderDataCondition={users.length > 0}
          renderRowProps={() => {
            return { style: { height: '59px' } };
          }}
          data={filteredusers.map((u, i) => {
            return { ...u, componentKey: i };
          })}
          handleSortClick={handleSortClick}
          getSortByOrder={getSortByOrder}
          rowsBorderLess={true}
        />
        {toastNotificationConfig.display && (
          <ToastNotification
            message={toastNotificationConfig.message}
            type={toastNotificationConfig.type}
            handleClose={resetToastMessage}
            zIndex={zIndices.mobilePopup}
          />
        )}
      </TableAndFiltersWrapper>

      {invitePopupDisplay && (
        <InviteUsersPopup closePopup={() => setInvitePopupDisplay(false)} setToastMessage={setToastMessage} />
      )}
    </PageWithTableContainer>
  );
};
