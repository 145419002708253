import React from 'react';
import { FilterSectionContainer, FiltersSectionRow } from '../../../components/FilterSection/style';
import { CustomizedFilterField } from '@skillset/ui';
import { rolesOptions, statusesOptions } from '../constants';
import { zIndices } from '../../../assets/zIndices';

interface AdministrationFilterSectionProps {
  updateCheckboxField: (category: string, value: string, checked: boolean) => void;
  isCheckboxOptionChecked: (category: string, value: string) => boolean;
}

export const AdministrationFilterSection: React.FC<AdministrationFilterSectionProps> = ({
  updateCheckboxField,
  isCheckboxOptionChecked,
}) => {
  return (
    <FilterSectionContainer>
      <FiltersSectionRow>
        <span className="title">Filter by: </span>
        <CustomizedFilterField
          name="All users"
          type="customizedCheckbox"
          options={rolesOptions}
          updateCheckboxField={updateCheckboxField}
          isCheckboxOptionChecked={isCheckboxOptionChecked}
          zIndexBackdrop={zIndices.backdrop}
          zIndexFilterField={zIndices.filterField}
          zIndexMobilePopup={zIndices.mobilePopup}
        />
        <CustomizedFilterField
          name="Any status"
          type="customizedCheckbox"
          options={statusesOptions}
          updateCheckboxField={updateCheckboxField}
          isCheckboxOptionChecked={isCheckboxOptionChecked}
          zIndexBackdrop={zIndices.backdrop}
          zIndexFilterField={zIndices.filterField}
          zIndexMobilePopup={zIndices.mobilePopup}
        />
      </FiltersSectionRow>
    </FilterSectionContainer>
  );
};
