import React, { ComponentProps, PropsWithChildren } from 'react';
import {
  Placement,
  autoPlacement,
  FloatingPortal,
  offset,
  useFloating,
  FloatingArrow,
  UseFloatingOptions,
  arrow,
} from '@floating-ui/react';
import { DirectionOptionsOptions, Tooltip } from './ToolTip';
interface ToolTipProps {
  color?: string;
  fontWeight?: string;
  textColor?: string;
  className?: string;
  direction?: DirectionOptionsOptions;
  text: string;
  floatingPlacement: Placement;
  isDisplayed: boolean;
  zindex: number;
  children: (referenceCallback: (ref: HTMLElement | null) => void) => React.ReactNode;
  placementOffset?: number;
  portalize?: boolean;
  portalRoot?: HTMLElement | null | React.MutableRefObject<HTMLElement | null>;
  arrowRef?: React.MutableRefObject<null>;
}

const PossiblyPortal: React.FC<PropsWithChildren<ComponentProps<typeof FloatingPortal> & { portalize?: boolean }>> = ({
  children,
  portalize,
  ...props
}) => (portalize || props.root ? <FloatingPortal {...props}>{children}</FloatingPortal> : <>{children}</>);
export const FloatingToolTipWithTriggerElement: React.FunctionComponent<ToolTipProps> = ({
  text,
  color,
  fontWeight,
  textColor,
  floatingPlacement,
  className,
  isDisplayed,
  direction,
  children: TriggerElement,
  zindex,
  placementOffset,
  portalize,
  portalRoot,
  arrowRef,
}) => {
  const middleware: UseFloatingOptions['middleware'] = [
    offset(placementOffset || 10),
    autoPlacement({ allowedPlacements: [floatingPlacement] }),
  ];

  if (arrowRef) middleware.push(arrow({ element: arrowRef }));

  const { refs, strategy, x, y, context } = useFloating({ middleware });

  const { setReference, setFloating } = refs;
  const renderArrow = arrowRef ? (
    <FloatingArrow ref={arrowRef} context={context} fill={color ?? 'var(--almost-black)'} />
  ) : null;

  return (
    <>
      {TriggerElement(setReference)} {/* Render the children function */}
      <PossiblyPortal portalize={portalize} root={portalRoot}>
        {isDisplayed && (
          <Tooltip
            color={color}
            fontWeight={fontWeight}
            textColor={textColor}
            className={className}
            ref={setFloating}
            direction={direction ?? 'none'}
            style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
            zIndex={zindex}
          >
            {text}
            {renderArrow}
          </Tooltip>
        )}
      </PossiblyPortal>
    </>
  );
};
//Share simulation link
