import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';
import '@skillset/onelogin/index.css';
import './index.css';
import '@skillset/pallet';
import { getAuthenticatedUser, setupAmplify, platformAnalyticsInit } from '@skillset/onelogin';
import config from './config/aws.config';
import { BrowserRouter as Router } from 'react-router-dom';

setupAmplify(config);
store.dispatch(getAuthenticatedUser({ platformName: 'Sourcing' }));
platformAnalyticsInit(config.social.GOOGLE_ANALYTICS, config.social.TAG_MANAGER_ARGS);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
